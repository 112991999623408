<template>
  <v-app>
    <v-navigation-drawer
        v-if="isLoggedIn"
        app
        v-model="drawer"
        clipped
        dense
        temporary
    >
      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click="$router.push(item.route).catch(()=>{}); drawer=false;"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app
        color="blue darken-3"
        dark
        clipped-left
    >
      <v-app-bar-nav-icon v-if="isLoggedIn" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-uppercase">Angebotskalkulator</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    active: false,
    value: '',
    items: [
      {title: 'Einstellungen', icon: 'mdi-cog-outline', route: 'settings'},
      {title: 'Angebote', icon: 'mdi-clipboard-text',  route: 'offers'},
      {title: 'Abmelden', icon: 'mdi-logout', route: 'logout'},
    ],
  }),
  computed: {
    ...mapGetters(['isLoggedIn'])
  }
}
</script>
