<template>
  <div>
    <h1>Angebote</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createOffer"
            >
              <v-icon left>mdi-plus</v-icon>
              Angebot
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="offers"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="offers.length < 5"
      >
        <template v-slot:item.category="{ item }">
          {{getCategoryName(item.categoryId)}}
        </template>
        <template v-slot:item.publishingDate="{ item }">
            {{computedDateFormattedDatefns(item.publishingDate)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editOffer(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteOffer(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
<!--    <v-overlay value="true">xxxxx</v-overlay>-->
    <offer-edit-dialog ref="offerEditDialog" @updated="offerUpdated" @saved="offerSaved" />
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import OfferEditDialog from '@/components/OfferEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { format, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import store from "@/store";

export default {
  name: "OfferList",
  components: {
    OfferEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      categories: [],
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      offers: [
        {id: 1, title: 'Angebot 1', createdAt: new Date() },
        {id: 2, title: 'Angebot 1', createdAt: new Date() },
      ],
    }
  },
  methods: {
    createOffer () {
      this.$refs.offerEditDialog.show()
    },
    editOffer (item) {
      console.log("Item", item)
      this.$refs.offerEditDialog.show(item)
    },
    deleteOffer: function (offer) {
      this.$refs.confirmDelete.show({
        title: 'Angebot entfernen',
        text: `Wollen sie das Angebot <b>${offer.title}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deleteOffer(offer.id).then(() => {
          this.offers = this.offers.filter((p) => p.id !== offer.id)
          console.log('Item deleted')
        })
      })
    },
    offerSaved(offer) {
      console.log("Saved", offer)
      this.showEditDialog = false
      this.offers = [...this.offers, offer]
    },
    offerUpdated(offer) {
      console.log("updated", offer)
      this.offers = this.offers.map((p) => offer.id === p.id ? offer : p)
    },
    computedDateFormattedDatefns (date) {
      return date ? format(parseISO(date), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(category => category.id === categoryId)
      return category?.name ?? "-"
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log("BeforeRouteEnter")
    APIService.getOffersByUserId(store.state.user.id).then((data) => {
      console.log(data)
      console.log(data)
      next(vm => {
        vm.offers = data
      })
    })
  }
}
</script>

<style scoped>

</style>
