<template>
  <v-container>
    <v-card-text class="ma-0 pa-0">
      <v-tabs v-model="tabs">
        <v-tab href="#angebot">Angebot</v-tab>
        <v-tab href="#store-window">Nachkalkulation</v-tab>
        <v-tab-item value="angebot">
          <v-expansion-panels class="mb-4" multiple>
            <v-expansion-panel
                :key="1"
            >
              <v-expansion-panel-header>
                Zeitaufwand
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="text-h6 my-4"></div>
                <div :key="field.label" v-for="(field,index) in fieldsOfActivity">
                  <v-row>
                    <v-col
                        cols="12"
                        class="py-2"
                    >
                      <div class="my-0 subtitle-1 text-decoration-underline">{{ field.label }}</div>
                    </v-col>
                    <v-col
                        cols="5"
                        sm="5"
                        class="my-0"
                    >
                      <v-text-field
                          @focus="$event.target.select()"
                          class="my-0"
                          dense
                          label="Personentage"
                          v-model.number="input.workingHoursPerField[index].days"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="5"
                        sm="5"
                        class="my-0"
                    >
                      <v-text-field
                          @focus="$event.target.select()"
                          class="my-0"
                          dense
                          label="Stunden"
                          v-model.number="input.workingHoursPerField[index].hours"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip
                          bottom
                          autofocus
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <div class="hours-details pa-2 mb-4">
                          Gesamt Stunden: {{(calculated.totalHoursPerField ? calculated.totalHoursPerField[index] : 0) | number }}<br>
                          Zeit / Gewichtung: {{(calculated.weighting ? calculated.weighting[index].time : 0) | fixedNumber(2) }}<br>
                          Materialaufschlag: {{ settings.additionsMaterial[index]  | fixedNumber(2) }}<br>
                          Material / Gewichtung: {{(calculated.weighting ? calculated.weighting[index].material : 0 ) | fixedNumber(2) }}<br>
                        </div>
                      </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="pt-0 mt-0"
                    >
                      Gesamt: {{(calculated.totalHoursPerField ? calculated.totalHoursPerField[index] : 0) | number }} Std.
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="12">
                    <table style="border-collapse: collapse; border-style: solid;">
                      <thead>
                      <tr>
                        <th colspan="3">Berechnung Lohn</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Stunden Gesamt:</td>
                        <td colspan="2" class="text-center">{{calculated.totalHours}}</td>
                      </tr>
                      <tr>
                        <td>Lohn gesamt:</td>
                        <td class="currency">{{ settings.hourlyWage | currency}}</td>
                        <td class="currency">{{calculated.workTotalPrice | currency }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
                :key="2"
            >
              <v-expansion-panel-header>
                Material / Fremdleistungen
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <table style="border-collapse: collapse; border-style: solid;">
                      <thead>
                      <tr>
                        <th colspan="3">Berechnung Material</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <th colspan="2">Material-Einsatz in Euro ohne Aufschlag:<br>
                          <span class="caption">(Basis Mittlerer Wert für Material-Aufschlag in %)</span>
                        </th>
                      </tr>
                      <tr>
                        <td class="text-center">{{calculated.totalMaterialAdditionPercentage | fixedNumber(2)}}% </td>
                        <td class="currency">{{calculated.materialPreis | currency}} </td>
                      </tr>
                      <tr>
                        <th colspan="2">Aufschlag Material in %:</th>
                      </tr>
                      <tr style="border-collapse: collapse; border-style: solid;">
                        <td class="input">
                          <span style="position: absolute; margin-left: 1px; margin-top: 1px;">%</span>
                          <input
                              @focus="$event.target.select()"
                              v-model.number="input.additionMaterial"
                          />
                        </td>
                        <td class="currency">{{ calculated.materialAufschlag | currency}}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Material gesamt:</th>
                      </tr>
                      <tr>
                        <td colspan="2" class="currency"> {{ calculated.materialPreisGesamt | currency }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col cols="12">
                    <table style="border-collapse: collapse; border-style: solid;">
                      <thead>
                      <tr>
                        <th colspan="2"> Berechnung Fremdleistungen</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Fremdleistungen</td>
                        <td class="input" colspan="2">
                          <span style="position: absolute; margin-left: 1px; margin-top: 1px;">€</span>
                          <input
                              @focus="$event.target.select()"
                              type="text" v-model.number="input.externalServices"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Zuschlag auf Fremdleistungen</th>

                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ this.settings.additionExternalServices }}%
                        </td>
                        <td class="currency">{{ calculated.zuschlagFremdleistungen | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Fremdleistung gesamt</th>
                      </tr>
                      <tr>
                        <td colspan="2" class="currency">{{ calculated.fremdleistungenGesamt | currency }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>

            <v-col cols="12">
              <table style="border-collapse: collapse; border-style: solid;">
                <thead>
                <tr>
                  <th>Berechnung geschätzter Angebotspreis</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Angebotspreis netto geschätzt</th>
                </tr>
                <tr>
                  <td class="currency">
                    {{ this.calculated.angebotNetto | currency }}
                  </td>
                </tr>
                <tr>
                  <th>Mwst</th>
                </tr>
                <tr>
                  <td class="currency">{{ calculated.mwst | currency }}</td>
                </tr>
                <tr>
                  <th>Angebotspreis brutto geschätzt</th>
                </tr>
                <tr>
                  <td class="currency">{{ calculated.angebotBrutto | currency }}</td>
                </tr>
                <tr>
                  <th>Geplanter Verrechnungssatz</th>
                </tr>
                <tr>
                  <td class="currency">{{ calculated.verrechnungsSatz | currency }}</td>
                </tr>
                </tbody>
              </table>
            </v-col>

            <v-col cols="12" class="pt-8 pb-0">
              <v-slider
                  hint="Korrektur"
                  max="120"
                  min="80"
                  thumb-label="always"
                  v-model.number="input.correction"
              ></v-slider>
            </v-col>
            <v-col cols="12">
              <table style="border-collapse: collapse; border-style: solid;">
                <thead>
                <tr>
                  <th>Angebotspreis Korrigiert</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Angebotspreis netto geschätzt</th>
                </tr>
                <tr>
                  <td class="currency">
                    {{ calculated.angbotNettoKorrektur | currency }}
                  </td>
                </tr>
                <tr>
                  <th>Mwst</th>
                </tr>
                <tr>
                  <td class="currency">{{ (calculated.angebotBruttoKorrektur - calculated.angbotNettoKorrektur) | currency }}</td>
                </tr>
                <tr>
                  <th>Angebotspreis brutto geschätzt</th>
                </tr>
                <tr>
                  <td class="currency">{{ calculated.angebotBruttoKorrektur | currency }}</td>
                </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="store-window" value="store-window">
          <post-calculator :input="input" :settings="settings"/>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <div
        :class="totalBackgroundClass" class="text-left pl-4 font-weight-bold"
        style=" z-index: 10; position: fixed; height: 50px; width:100%;  bottom: 0; left: 0;  color: white;"
    >

      Preis: {{$root.calculated.angbotNettoKorrektur | currency}} ({{$root.calculated.angebotBruttoKorrektur | currency}})<br>
      <v-icon
          style="position: absolute; right: 5px; top: 5px"
          large
          size="20"
          color="yellow"
          v-if="$root.calculated.correction !== 100"
          class="ml-3"
          @click="input.correction = 100"
      >
        mdi-alert
      </v-icon>
      Verrechnungssatz: {{$root.calculated.verrechnungsSatzKorriegiert | currency}}
    </div>
  </v-container>
</template>

<script>

import PostCalculator from "@/components/PostCalculator";
export default {
  name: 'Calculator',
  components: {PostCalculator},
  props: {
    input: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      e1: '1',
      tabs: 'angebot',
      fieldsOfActivity: [
        {label: 'Innenputz', key: 'xx'},
        {label: 'Außenputz', key: 'xx'},
        {label: 'Trockenbau', key: 'xx'},
        {label: 'Gerüst', key: 'xx'},
        {label: 'Dämmung', key: 'xx'},
        {label: 'Sonstige', key: 'xx'},
      ],
      calculated: {}
    };
  },
  methods: {
    calculateData() {
        console.log("Value changed")
        const customizedHourCalculator = activityHours(this.settings.hoursPerDay)
        const totalHoursPerField = this.input.workingHoursPerField.map(field => customizedHourCalculator(field.hours, field.days))
        const totalHours = totalHoursPerField.reduce((acc, val) => acc +  (val ? val : 0), 0)
        const weighting  = totalHoursPerField.map((fieldTotalHours, index) => ({
          time: ((fieldTotalHours /  totalHours) * 100),
          material:  ((fieldTotalHours /  totalHours) * this.settings.additionsMaterial[index] ),
        }))
        const workTotalPrice = totalHours * this.settings.hourlyWage
        const totalMaterialAdditionPercentage = weighting.reduce((acc, val) => acc +  val.material, 0)
        const materialPreis = workTotalPrice * (totalMaterialAdditionPercentage / 100)
        const materialAufschlag = (materialPreis * this.input.additionMaterial) / 100
        const materialPreisGesamt = materialPreis + materialAufschlag
        const zuschlagFremdleistungen = this.input.externalServices * this.settings.additionExternalServices / 100
        const costsExternalServices = this.input.externalServices * this.settings.administrativeExpensesExternalServices / 100
        const profitExternalServices = zuschlagFremdleistungen - costsExternalServices
        const fremdleistungenGesamt = zuschlagFremdleistungen + this.input.externalServices
        const angebotNetto = workTotalPrice + materialPreisGesamt + fremdleistungenGesamt
        const mwst = angebotNetto * (this.settings.vatRate / 100)
        const angebotBrutto = angebotNetto + mwst
        const correction = this.input.correction
        const angbotNettoKorrektur = angebotNetto * correction / 100
        const mwstKorrektur = angbotNettoKorrektur *  (this.settings.vatRate / 100)
        const angebotBruttoKorrektur = angbotNettoKorrektur + mwstKorrektur
        const verrechnungsSatz = (workTotalPrice + materialAufschlag + profitExternalServices) / totalHours
        const verrechnungsSatzKorriegiert = Number(((workTotalPrice + materialAufschlag + profitExternalServices) * correction)  / 100 / totalHours).toFixed(2)

        this.calculated = {
          totalHoursPerField,
          totalHours,
          workTotalPrice,
          weighting,
          totalMaterialAdditionPercentage,
          materialPreis,
          materialPreisGesamt,
          materialAufschlag,
          zuschlagFremdleistungen,
          costsExternalServices,
          profitExternalServices,
          fremdleistungenGesamt,
          angebotNetto,
          mwst,
          angebotBrutto,
          correction,
          angbotNettoKorrektur,
          angebotBruttoKorrektur,
          mwstKorrektur,
          verrechnungsSatz,
          verrechnungsSatzKorriegiert,
        }
        this.$root.calculated = this.calculated
      }
  },
  watch: {
    input: {
      deep: true,
      handler() {
        this.calculateData()
      }
    }
  },
  computed: {
    totalBackgroundClass() {
      console.log('totalBackgroundClass', this.$root.calculated.verrechnungsSatzKorriegiert, this.$root.settings.hourlyWage )
      return !this.$root.calculated.verrechnungsSatzKorriegiert ||
      this.$root.calculated.verrechnungsSatzKorriegiert >= this.$root.settings.hourlyWage  ? 'green' : 'red'
    },
  },
  mounted() {
    this.calculateData()
  }
}
const activityHours = hoursPerDay => (hours, days) => hoursPerDay * days + parseInt(hours)
</script>
<style lang="scss" scoped>
table {
  width: 100%;
}
thead th {
  background-color: lightskyblue;
}
th {
  background-color: lightgrey;
  padding: 3px;
}
td {
  border: 1px solid;
  padding: 3px;
  &.currency {
    text-align: center;
  }
}
td.input {
  text-align: right;
  input {
    background-color: yellow;
    width: 100%;
    text-align: right;
  };
}
.green {
  color: green;
}
.red {
  color: red;
}
</style>
