<template>
  <div>
    {{huhu}}
    <hello-world :input="input" :settings="settings"/>
    <button @click="$router.push({name: 'Offer', params: {offerId: offerId+1 }})">Test</button>
  </div>
</template>

<script>
  import HelloWorld from '../components/Calculator'

  function beforeRouteChange(to, from, vm) {
    console.log("Before route change called", vm)
    vm.huhu = "test" + to.path
    return true
  }

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },
    props: ['offerId'],
    data() {
      return {
        input: {
          workingHoursPerField: [
            {hours: 0, days: 0, additionMaterial: 20},
            {hours: 0, days: 0, additionMaterial: 25},
            {hours: 0, days: 0, additionMaterial: 30},
            {hours: 0, days: 0, additionMaterial: 2},
            {hours: 0, days: 0, additionMaterial: 35},
            {hours: 0, days: 0, additionMaterial: 25},
          ],
          fremdLeistungen: 0,
          aufschlagMaterial: 0,
          korrektur: 100
        },
        settings: {
          hoursPerDay: 8,
          hourlyWage: 59.20,
          aufschlagFremdleistungen: 25,
          mehrwertSteuerSatz: 19,
        },
        huhu: ''
      }
    },
    mounted() {
      this.input = {...this.input}
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        beforeRouteChange(to, from, vm)
      })
    },
    beforeRouteUpdate(to, from, next) {
      beforeRouteChange(to, from, this)
       next()
    },
  }

</script>
