import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OfferList from "@/views/OfferList";
import Login from "@/views/Login";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/offers',
    name: 'Offers',
    component: OfferList
  },
  {
    path: '/offers/:offerId',
    name: 'Offer',
    props: true,
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from ,next) => {
      const { email, password, token} = to.query
      if (to.query.token) {
        store.dispatch('login', {email, password, token}).then(() => {
          next('/offers')
        }).catch(() => {
          next()
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from, next) => {
      store.dispatch('logout').then(() => {
        next('/login')
      })
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings')
  },
  {
    path: '/sheet',
    name: 'Sheet',
    component: () => import(/* webpackChunkName: "calculator" */'../views/Sheet')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( (to, from, next) => {
  console.log("Check if user logged in", to.path, store.state.user)
  if(to.path  !== '/login' && !store.state.user) {
    console.log("User not logged in")
    next('/login')
  }
  next()
});

export default router
