import axios from "axios";
import router from "@/router";
import store from "@/store";

console.log("Initializing Module")
const config = {
    baseURL: `${process.env.VUE_APP_ROOT_API}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
}
let originalUrl = null

const apiClient = axios.create(config)

function authHeaderInterceptor(config) {
    const token = store.state?.user?.tokens?.access?.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

function accessTokenRefreshInterceptor(error) {
    const status = error.response.status
    const config = error.config
    console.log('originalUrl', originalUrl)
    if(config.url !== '/auth/refresh-tokens') {
        originalUrl = config.url
    }
    if (status !== 401) {
        return Promise.reject(error);
    }
    if (config.url === '/auth/login'
        || config.url === '/sso/authTokens') {
        return Promise.reject(error);
    }

    // TODO check if user is disabled
    if (config.url === '/auth/refresh-tokens') {
        store.commit('logout')
        if(originalUrl !== '/sso/authCode') {
            router.push({ name: 'adminLogin'});
        }
        return Promise.reject(error);
    }
    const refreshToken = store.getters.refreshToken ? store.getters.refreshToken : 'invalid_token'
    if(!config._retry) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            const tokens = response?.data
            store.commit('updateTokens', tokens)
            config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;
            config._retry = true
            return apiClient.request(config)
        })
    }
}

apiClient.interceptors.request.use((config) => {
    return authHeaderInterceptor(config)
})
apiClient.interceptors.response.use(response => {
    return response
}, error => {
    return accessTokenRefreshInterceptor(error)
})

export default {
    login({email, password, token}) {
        return apiClient.post('/auth/login', { email, password, token }).then(response => response.data)
    },
    logout(refreshToken) {
        return apiClient.post('/auth/logout', { refreshToken })
    },
    refreshToken(refreshToken) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            return response.data
        })
    },
    getUsers() {
        return apiClient.get('/users?limit=1000').then(response => {
            return response.data.results
        })
    },
    createUser(user) {
        return apiClient.post("/users", user).then(result => {
            return result.data
        })
    },
    updateUser(userData) {
        const data = { ...userData }
        const userId = data.id
        delete data.id
        delete data.isEmailVerified
        console.log(userData)
        return apiClient.patch(`/users/${userId}`, data).then(result => {
            return result.data
        })
    },
    deleteUser(userId) {
        console.log("Delete user", userId)
        return apiClient.delete(`/users/${userId}`).then(response => {
            return response.data
        })
    },
    getOffersByUserId(userId) {
        console.log('getOffers', userId)
        return apiClient.get(`/users/${userId}/offers`).then(response => {
            return response.data.results
        })
    },
    getOffer(offerId) {
        console.log('getOffer', offerId)
        return apiClient.get(`/offers/${offerId}`).then(response => response.data)
    },
    createOffer(offer) {
        console.log("Save offer", offer)
        return apiClient.post(`/offers`, offer).then(response => {
            return response.data
        })
    },
    updateOffer(offer) {
        console.log("Update offer", offer)
        let p = { ...offer }
        delete p.id
        delete p.user
        return apiClient.patch(`/offers/${offer.id}`, p).then(response => {
            return response.data
        })
    },
    deleteOffer(offerId) {
        console.log("Delete offer", offerId)
        return apiClient.delete(`/offers/${offerId}`).then(response => {
            return response.data
        })
    },
}
