import Vue from 'vue'
import Vuex from 'vuex'
import APIService from "@/services/APIService";
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    users: [],
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user !== null
    },
    accessToken: (state) => {
      return state?.user?.tokens?.access?.token
    },
    refreshToken: (state) => {
      return state?.user?.tokens?.refresh?.token
    }
  },
  mutations: {
    updateUser(state, data) {
      console.log("Data", data)
      const {user, tokens} = data
      state.user = user
      state.user.tokens = tokens
    },
    updateTokens(state, tokens) {
      state.user = { ...state.user, tokens }
    },
    logout(state) {
      state.user = null
    },
  },
  actions: {
    login({ commit }, { email, password, token }){
      console.log("Login", email, password)
      return APIService.login({email, password, token}).then((user) => {
        console.log("Response", user)
        commit('updateUser', user)
      })
    },
    logout({commit, getters}){
      console.log("Logout Action called")
      if(getters.isLoggedIn) {
        APIService.logout(getters.refreshToken).then()
        commit('logout')
      }
    },
    refreshToken({commit,state}) {
      const token = state?.user?.tokens?.refresh?.token ?? 'invalid'
      return APIService.refreshToken(token).then(tokens => {
        commit('updateTokens', tokens)
        return tokens
      })
    },
  },
  modules: {
  },
  plugins: [ createPersistedState() ]
})
