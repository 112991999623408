import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCurrencyFilter from 'vue-currency-filter'
import store from './store'
import './filters'
Vue.config.productionTip = false
Vue.use(VueCurrencyFilter,
    {
        symbol : '€',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'back',
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
    })
new Vue({
    router,
    vuetify,
    data() {
        return {
            calculated: {},
            settings: {
                hoursPerDay: 8,
                hourlyWage: 59.20,
                additionExternalServices: 25,
                administrativeExpensesExternalServices: 18,
                vatRate: 19,
                additionsMaterial: [20, 25, 30, 2, 35, 35]
            }
        }
    },
    store,
    render: h => h(App)
}).$mount('#app')
