<template>
  <div class="post-calculator">
    <v-row>
      <v-col
          cols="12"
          class="pb-0 my-0 mb-0 mt-4"
      >
        <div>Arbeitszeit</div>
      </v-col>
      <v-col
          cols="6"
          class="my-0"
      >
        <v-text-field
            @focus="$event.target.select()"
            class="my-0"
            label="Stunden"
            v-model.number="input.hours"
        ></v-text-field>
      </v-col>
      <v-col
          cols="6"
          class="my-0"
      >
        <v-text-field
            @focus="$event.target.select()"
            class="my-0"
            label="Tage"
            v-model.number="input.days"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          class="my-0"
      >
        <v-text-field
            @focus="$event.target.select()"
            class="my-0"
            dense
            label="Materialkosten"
            v-model.number="input.materialCosts"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          class="my-0"
      >
        <v-text-field
            @focus="$event.target.select()"
            class="my-0"
            dense
            label="Fremdleistungen"
            v-model.number="input.externalServicesCosts"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          class="my-0"
      >
        <v-text-field
            @focus="$event.target.select()"
            class="my-0"
            dense
            label="Verwaltungskosten Fremdleistungen"
            v-model.number="input.externalServicesAdministrativeExpenses"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="text-h6">Angebots-Preis: {{$root.calculated.angbotNettoKorrektur | currency}}</div>
        <div class="text-h6">Kosten: {{this.calculated.actualTotalCosts | currency}}</div>
        <div class="text-h6">Überschuss / Fehlbetrag: {{this.calculated.ertragGesamt | currency}}</div>
        <div v-if="calculated.actualWorkHours"  class="text-h6 billing-rate">Verrechnungssatz: {{ this.calculated.actualBillingRate | currency}}</div>
      </v-col>
      <v-col cols="12">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Geplant</th>
              <th>Tatsächlich</th>
              <th>Differenz</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>Lohnkosten</td>
            <td>{{$root.calculated.workTotalPrice | currency}}</td>
            <td>{{this.calculated.actualWorkCosts | currency}}</td>
            <td :class="{negative: this.calculated.diffWorkCosts < 0}">{{this.calculated.diffWorkCosts | currency}}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{{$root.calculated.materialPreisGesamt | currency}}</td>
            <td>{{this.input.materialCosts | currency}}</td>
            <td :class="{negative: this.calculated.diffMaterial < 0}">{{this.calculated.diffMaterial | currency}}</td>
          </tr>
          <tr>
            <td>Fremdleistungen</td>
            <td>{{$root.calculated.fremdleistungenGesamt | currency}}</td>
            <td>{{this.calculated.fremdleistungenGesamt | currency}}</td>
            <td :class="{negative: this.calculated.diffFremdleistungen < 0}">{{this.calculated.diffFremdleistungen | currency}}</td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  name: 'PostCalculator',
  props: {
    settings: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      input: {
        hours: 0,
        days: 0,
        materialCosts: 0,
        externalServicesCosts: 0,
        externalServicesAdministrativeExpenses: 0,
      },
      calculated: {}

    };
  },
  methods: {
    calculateData() {
      const actualWorkHours = this.input.hours + this.input.days * this.$root.settings.hoursPerDay
      const actualWorkCosts =  actualWorkHours * this.settings.hourlyWage
      const diffWorkCosts =  this.$root.calculated.workTotalPrice - actualWorkCosts
      const diffMaterial =  this.$root.calculated.materialPreisGesamt - this.input.materialCosts
      const fremdleistungenGesamt = this.input.externalServicesCosts + this.input.externalServicesAdministrativeExpenses
      const diffFremdleistungen =  this.$root.calculated.fremdleistungenGesamt - fremdleistungenGesamt
      const actual = this.$root.calculated.angbotNettoKorrektur + diffWorkCosts +  diffFremdleistungen + diffMaterial
      const actualTotalCosts = actualWorkCosts + this.input.materialCosts + fremdleistungenGesamt
      const ertragGesamt  = this.$root.calculated.angbotNettoKorrektur - actualTotalCosts
      const actualBillingRate =  (this.$root.calculated.angbotNettoKorrektur - fremdleistungenGesamt - this.input.materialCosts) / actualWorkHours
      this.calculated = {
        actualWorkHours,
        actualWorkCosts,
        actualBillingRate,
        fremdleistungenGesamt,
        diffFremdleistungen,
        diffMaterial,
        diffWorkCosts,
        actual,
        actualTotalCosts,
        ertragGesamt,
      }
    }
  },
  watch: {
    input: {
      deep: true,
      handler() {
        this.calculateData()
      }
    }
  },
  computed: {
    billingRateClass() {
      console.log('totalBackgroundClass', this.$root.calculated.verrechnungsSatzKorriegiert, this.$root.settings.hourlyWage )
      return !this.calculated.actualBillingRate ||
          (this.calculated.actualBillingRate >= this.$root.settings.hourlyWage  ? 'green' : 'red')
    }
  },
  mounted() {
    this.calculateData()
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
}
thead th {
  background-color: lightskyblue;
}
th {
  background-color: lightgrey;
  padding: 3px;
}
td {
  border: 1px solid;
  padding: 3px;
  &.currency {
    text-align: center;
  }
}
td.input {
  text-align: right;
  input {
    background-color: yellow;
    width: 100%;
    text-align: right;
  };
}
.green {
  color: green;
}
.negative {
  color: red;
  border-color: black;
}
.red {
  color: red;
  border-color: black;
}
.billing-rate {
  background-color: white !important;
}
.post-calculator {
  max-width: 535px;
}
</style>
