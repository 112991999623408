<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        width="auto"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Angebot</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-if="offer"
                      v-model="offer.title"
                      label="Titel"
                      required
                      :rules="requiredRule('Titel')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-if="offer"
                      v-model="offer.description"
                      label="Beschreibung"
                      required
                      :rules="requiredRule('Beschreibung')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <hello-world v-if="showDialog" :input="offer.calculationData" :settings="offer.settings"/>
        </v-card-text>
        <v-card-actions class="mb-12">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveOffer"
          >
            {{ offer && offer.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>
<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import Snackbar from '@/components/common/Snackbar'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {mapState} from "vuex";
import HelloWorld from "@/components/Calculator";

const CALCULATOR_TEMPLATE = {
  title: '',
  description: '',
  calculationData: {
    workingHoursPerField: [
      { hours: 0, days: 0 },
      { hours: 0, days: 0 },
      { hours: 0, days: 0 },
      { hours: 0, days: 0 },
      { hours: 0, days: 0 },
      { hours: 0, days: 0 },
    ],
    externalServices: 0,
    additionMaterial: 0,
    correction: 100
  }
}

export default {
  name: 'OfferEditDialog',
  components: { Snackbar, ConfirmDialog, HelloWorld },
  data: () => ({
    categories: [],
    showDialog: false,
    offer: null,
    snackbarSuccess: {
      text: 'Offer erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    menu: false,
 //   user: {id: 123}
  }),
  methods: {
    async show (offer) {
      if(offer)  {
        this.offer = await APIService.getOffer(offer.id)
        console.log(this.offer)
      } else {
        this.offer = { ...CALCULATOR_TEMPLATE, settings: this.settings, user: this.user.id }
      }
      console.log("huhu")
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.offer = {}
      this.$el.scrollTop = 0
      this.$refs.userForm.resetValidation()
    },
    async saveOffer () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      if (this.offer.id) {
        APIService.updateOffer(this.offer).then((response) => {
          console.log("Update Response", response, this.offers)
          this.$emit('updated', this.offer)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.createOffer(this.offer).then((offer) => {
          console.log("Save Response", offer, this.offers)
          this.$emit('saved', offer)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    ...mapState(['user']),
    computedDateFormattedDatefns () {
      return this.offer.publishingDate ? format(parseISO(this.offer.publishingDate), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
    settings() {
      return this.$root.settings
    }
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    }
  },
  mounted() {
    // APIService.get .getCategories().then((categories) => {
    //   this.categories = this.user.role === 'user'? categories.filter(category => category.type === 'company') : categories
    // })
  }
}
</script>

<style scoped>

</style>
